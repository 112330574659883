// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"

import jQuery from 'jquery'
window.jQuery = jQuery
window.$ = jQuery

import 'jquery';
import 'jquery-validation';

window.stickyNow = function() {
  if (window.pageYOffset > sticky) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
}
window.onscroll = function() {stickyNow()};

// Get the header
var header = document.getElementById("header-section");

// Get the offset position of the navbar
var sticky = header.offsetTop;
